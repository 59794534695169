.about-container {
  padding-top: 0;
  margin: 0.8rem 3rem;
  text-align: start;
}

.about-container h1 {
  padding-top: 0.1rem;
  padding-bottom: 1rem;
}

.about-container h2 {
  padding-top: 4rem;
  padding-bottom: 1rem;
}

.about-container p {
  padding-bottom: 2rem;
}

@media screen and (max-width: 850px) {
  .about-container {
    margin: 4rem 2rem;
  }
}
