@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;700;800;900&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

.App {
  text-align: center;
}

.decoration {
  text-align: start;
  font-family: "Poppins", sans-serif;
}

.decoration .button-decor {
  background-color: #4bb5b9;
}

@media screen and (max-width: 850px) {
  
  #react-doc-viewer { 
    padding-left: 0;
    margin-left: 0;
  }

  #react-doc-viewer #header-bar {
    padding-left: 0;
    margin-left: 0;
  }

  .decoration {
    padding-left: 0;
    margin-left: 0;
  }

  .decoration .docViewerStyle {
    padding-left: 0;
    margin-left: 0;
  }

  .decoration .button-decor {
    display: flex;
    background-color: #4bb5b9;
   }

  .decoration .secondary {
    display: flex;
  }
}