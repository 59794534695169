.insight {
  margin: 6rem 4rem;
  color: #2a2a2a;
}

.insight h1 {
  font-size: 2rem;
}

.insightcard {
  margin-top: 3rem;
  display: flex;
  justify-content: space-between;
}

.i-card {
  width: 32%;
  text-align: start;
  box-shadow: 0 5px 25px 2px rgba (0, 0, 0, 0.11);
  border-radius: 7px;
  cursor: pointer;
  padding: 1rem 0.8rem;
}

.i-image {
  height: 200px;
  overflow: hidden;
  border-radius: 7px;
}

.i-image img {
  width: 100%;
  height: 100%;
  transition: 0.3s ease-in-out;
  border-radius: 7px;
}

.i-image:hover img {
  transform: scale(1.5);
}

.i-card h4 {
  font-size: 1.1rem;
  padding: 0.9rem 0 0.2rem 0;
}

@media screen and (max-width: 850px) {
  .insight {
    margin: 4rem 2rem;
  }

  .insightcard {
    flex-direction: column;
  }

  .i-card {
    width: 100%;
    margin-bottom: 1.5rem;
  }
}
